import { db, storage, auth } from 'firebase';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, doc, deleteDoc, getDocs, getDoc, addDoc, updateDoc } from "firebase/firestore"; 

class _apiFirebase {
	constructor(db) {
		this.db = db
	}

	async getAll(path) {
		try{
			const snapshot = await getDocs(collection(this.db, path))
			const posts = []
			snapshot.forEach(doc => {
				posts.push({
					id: doc.id,
					...doc.data()
				})
			})
			return posts.reverse();
		} catch(err){
			throw err;
		}
	
  }

	async get(path, data) {
		try{
			const {id} = data;
			const docRef = doc(this.db, path, id);
			const docSnap = await getDoc(docRef);
			return {id, ...docSnap.data()};
		} catch(err){
			throw err;
		}		
	}

	async update(path, id, data) {
		try{
			const ref = doc(this.db, path, id);
			const response = await updateDoc(ref, data);
			return response;
		} catch(err){
			throw err;
		}
	}

	async create(path, data) {
		try{
			const response = await addDoc(collection(this.db, path), data);
			return response.id;
		} catch(err){
			throw err;
		}
	}

	async remove(path, id) {
		try{
			return await deleteDoc(doc(this.db, path, id));
		} catch(err){
			throw err;
		}
	}
}

class _apiStorage {
	constructor(storage, ref, uploadBytes) {
		this.storage = storage
		this.ref = ref
		this.uploadBytes = uploadBytes
	}
	
	async upload(path, file) {
		try{
			const imgRef = this.ref(this.storage, path);
			await this.uploadBytes(imgRef, file)
			const url = await getDownloadURL(imgRef)
			return url;
		} catch(err){
			throw err;
		}
	}
}

class _apiAuth {
	constructor(auth, signInWithEmailAndPassword) {
		this.auth = auth
		this.signInWithEmailAndPassword = signInWithEmailAndPassword
	}
	
	async login(data) {
		try{
			const {email, password} = data;
			const response = await this.signInWithEmailAndPassword(this.auth, email, password)
			return response.user;
		} catch(err){
			throw err;
		}
	}

	async logout() {
		try{
			const response = await this.auth.signOut();
			return response;
		} catch(err){
			throw err;
		}
	}
}

export const admin = new _apiFirebase(db)
export const apiStorage = new _apiStorage(storage, ref, uploadBytes)
export const apiAuth = new _apiAuth(auth, signInWithEmailAndPassword)