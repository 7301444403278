import React from 'react'
import PropTypes from 'prop-types'

import api from 'Admin/api/client'

const AuthContext = React.createContext({})

const AuthProvider = ({ children }) => {
	const [user, setUser] = React.useState(undefined)
	const [error, setError] = React.useState(undefined)
	const [loading, setLoading] = React.useState(true)

	React.useEffect(() => {
		const lsUser = JSON.parse(window.localStorage.getItem('USER_COLLIA'))

		if (lsUser !== null) {
			setUser(lsUser)
		}

		setLoading(false)
	}, [])

	React.useEffect(() => {
		if (user) {
			window.localStorage.setItem('USER_COLLIA', JSON.stringify(user))
		}
	}, [user])

	const login = React.useCallback(
		async (form) => {
			setLoading(true)
			setError(undefined)
			try {
				const response = await api.login(form)
				console.log(response)
				let newUser = { ...form }
				setUser(newUser)
			} catch (e) {
				setError(e)
				console.error(e)
			}
			setLoading(false)
		},
		[],
	)
	

	const actions = React.useMemo(
		() => ({
			login,
		}),
		[login],
	)

	const stateMemo = React.useMemo(
		() => ({
			state: { user, error, loading },
			actions,
		}),
		[user, actions, error, loading],
	)
		
	return <AuthContext.Provider value={stateMemo}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export { AuthProvider as Provider, AuthContext as default }
