import React from 'react'
import { Switch, BrowserRouter as Router } from 'react-router-dom'
import Route from 'router/components/Route'

import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
import 'styles/Global.scss'

const HomePage = React.lazy(() => import('pages/Home'))
const radio = React.lazy(() => import('pages/Nosotros'))
const nosotros = React.lazy(() => import('pages/PreguntasFrecuentes'))
const AdminPage = React.lazy(() => import('pages/Admin'))
const LoginPage = React.lazy(() => import('pages/Login'))
const PostPage = React.lazy(() => import('pages/Post'))
const SinglePostPage = React.lazy(() => import('pages/SinglePost'))

function App() {
	return (
		<Router>
			<React.Suspense fallback={<div></div>}>
				<Switch>
					<Route exact component={HomePage} path='/' />
					<Route component={nosotros} path='/nosotros' />
					<Route component={radio} path='/radio' />
					<Route component={SinglePostPage} path='/post/:id' />
					<Route onlyForAuth component={LoginPage} path='/login' />
					<Route authNeeded component={PostPage} path='/admin/post/:id' />
					<Route authNeeded component={AdminPage} path='/admin' />
				</Switch>
			</React.Suspense>
		</Router>
	)
}

export default App
