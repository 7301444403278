import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBR4wd0z-11TOVZ4CgN4HiCPq7_DGzSw3s",
  authDomain: "dr-collia.firebaseapp.com",
  projectId: "dr-collia",
  storageBucket: "dr-collia.appspot.com",
  messagingSenderId: "74844386609",
  appId: "1:74844386609:web:2d70e44add8f834c6b6c10",
  measurementId: "G-J1Q36VKH4H"
};

// Initialize
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage(app, 'gs://dr-collia.appspot.com');