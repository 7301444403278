import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider as AuthProvider } from 'Admin/context'

ReactDOM.render(
	<React.StrictMode>
		<AuthProvider>
			<App />
		</AuthProvider>
	</React.StrictMode>,
	document.getElementById('root'),
)
