import {admin, apiStorage as storage, apiAuth as auth} from 'api'

const adminApi = {
	login: (data) => auth.login(data),

	getPost: (data) => admin.get('/blog', data),
	listPosts: () => admin.getAll('/blog'),
	createPost: (data) => admin.create('/blog', data),
	updatePost: (id, data) => admin.update('/blog', id, data),
	deletePost: (id) => admin.remove('/blog', id),

	uploadImage: (path, file) => storage.upload(path, file),
}
export default adminApi
